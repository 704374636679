import { Component, OnInit } from '@angular/core';
import {IonContent, ModalController} from '@ionic/angular';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {

  name = "";
  email = "";
  content = "";

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  close() {
    this.modalController.dismiss()
  }

}
